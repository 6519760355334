










































































































































































































.page-support {
  .card-body {
    .table-reponsive {
      width: 100%;
      overflow-x: auto;
    }
  }
  .box-write,
  .box-table {
    box-shadow: 0px 0px 10px 2px #00000038;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px 0;
    margin-bottom: 15px;
    margin: 0 auto 15px;
    position: relative;
    width: calc(100% - 20px);
    min-height: 250px;
    .title {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: center;
      padding: 0 0 5px;
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
      text-transform: uppercase;
      width: 100%;
    }
    form {
      width: 100%;
      padding: 10px 20px;
      .form-group {
        width: 100%;
        label {
          font-weight: 600;
          color: #6c757d;
          font-size: 16px;
          margin-bottom: 0px;
        }
        select,
        input {
          border: 0;
          border-radius: 0;
          border-bottom: 2px solid #6c757d;
          height: 40px;
          font-size: clamp(15px, 1vw, 16.2px);
          font-weight: 600;
          &:focus {
            box-shadow: none;
            border-bottom: 2px solid #097501;
          }
          &::placeholder {
            font-weight: 500;
            font-size: clamp(13px, 1vw, 15px);
          }
        }
        button {
          border: 1px solid #ccc;
          box-shadow: 0 2px 6px 0 #ccc;
          letter-spacing: 0.05rem;
          position: relative;
          background: #009750;
          color: #fff !important;
          min-width: 130px;
          width: 100%;
          height: 45px;
          overflow: hidden;
          transition: 0.3s ease-in-out;
          border-radius: 0.3rem;
          z-index: 1;
          &:hover,
          &:focus,
          &:focus-visible {
            box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
  .box-detail {
    background-color: rgba(0, 0, 20, 0.5);
    border: 1px solid rgb(255 255 255);
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
  }
  .box-table {
    width: 100%;
    .box-reponsive {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      table {
        width: 100%;
        .table th,
        .table td {
          min-width: 100px;
          white-space: pre;
        }
      }
    }
  }
}
